<template>
  <div v-bind="containerProps">
    <slot name="left"></slot>
    <v-text-field
      v-bind="searchProps"
      :value="searchValue"
      @input="handlerSearch"
    ></v-text-field>
    <v-divider v-bind="dividerProps"></v-divider>
    <slot></slot>
    <v-spacer></v-spacer>
    <slot name="right"></slot>
    <v-btn v-bind="refreshProps" @click="$emit('refresh')">
      <v-icon>{{ refreshIcon }}</v-icon>
    </v-btn>
    <v-btn v-bind="newProps" @click="$emit('new')">{{ newLabel }}</v-btn>
  </div>
</template>

<script>
export default {
  name: "TableHeaderElement",
  props: {
    containerProps: {
      type: Object,
      default: () => {},
      description: "Propiedades del contenedor",
    },
    searchValue: {
      type: String,
      default: "",
      description: "Valor del buscador",
    },
    searchProps: {
      type: Object,
      default: () => {},
      description: "Propiedades del buscador",
    },
    dividerProps: {
      type: Object,
      default: () => {},
      description: "Propiedades del divisor",
    },
    refreshProps: {
      type: Object,
      default: () => {},
      description: "Propiedades del boton de refrescar",
    },
    refreshIcon: {
      type: String,
      default: "mdi-refresh",
      description: "Icono para el boton de refrescar",
    },
    newProps: {
      type: Object,
      default: () => {},
      description: "Propiedades del boton de crear nuevo",
    },
    newLabel: {
      type: String,
      default: "Nuevo",
      description: "Nombre para el boton de crear nuevo",
    },
  },
  methods: {
    handlerSearch(e) {
      this.$emit("search", e);
    },
  },
};
</script>
